import React, { useState, useEffect } from "react"
import tw, { styled } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"

import InsightsAcademyCard from "components/insights-academy-card"

const Styles = styled.div`
  ${tw`w-full flex flex-col space-y-24`}

  .tier {
    ${tw`w-full flex flex-col items-center justify-center`}

    h5 {
      ${tw`font-bold uppercase`}
    }

    & > div.description {
      ${tw`text-white text-lg`}

      max-width: 800px;
    }

    .items-wrapper {
      ${tw`w-full`}

      .items {
        ${tw`grid gap-x-5 gap-y-10 justify-items-center`}

        .item {
          ${tw`w-full`}

          &:nth-child(n + 5) {
            ${tw`hidden`}
          }
        }

        @media (min-width: 768px) {
          grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
        }

        @media (min-width: 1024px) {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

          .item:nth-child(n) {
            ${tw`block`}
          }

          .item:nth-child(n + 7) {
            ${tw`hidden`}
          }
        }

        @media (min-width: 1200px) {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

          .item:nth-child(n) {
            ${tw`block`}
          }

          .item:nth-child(n + 9) {
            ${tw`hidden`}
          }
        }

        &.show-all {
          @keyframes become-visible {
            0% {
              display: none;
              opacity: 0;
            }
            1% {
              display: block;
              opacity: 0;
            }
            100% {
              display: block;
              opacity: 1;
            }
          }

          @media (max-width: 1024px) {
            .item:nth-child(n + 5) {
              ${tw`block`}

              animation: become-visible 0.5s ease-out;
            }
          }

          @media (min-width: 1024px) and (max-width: 1200px) {
            .item:nth-child(n + 7) {
              ${tw`block`}

              animation: become-visible 0.5s ease-out;
            }
          }

          @media (min-width: 1200px) {
            .item:nth-child(n + 9) {
              ${tw`block`}

              animation: become-visible 0.5s ease-out;
            }
          }
        }
      }

      .load-more {
        ${tw`py-4 rounded font-bold uppercase text-white w-full text-center cursor-pointer`}

        background: rgba(255, 255, 255, 0.07);

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.05),
              rgba(255, 255, 255, 0.05)
            ),
            rgba(255, 255, 255, 0.07);
        }
      }
    }
  }
`

function TierItems({ tier }) {
  const [showAll, setShowAll] = useState(false)
  const [showAllAvailable, setShowAllAvailable] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 768 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 })
  const isTabletLandscape = useMediaQuery({ minWidth: 1024, maxWidth: 1200 })
  const isDesktop = useMediaQuery({ minWidth: 1200 })

  const { insightsArticles: articles } = tier

  function isShowAllAvailable() {
    return (
      ((isMobile || isTablet) && articles.length > 4) ||
      (isTabletLandscape && articles.length > 6) ||
      (isDesktop && articles.length > 8)
    )
  }

  useEffect(() => {
    setShowAllAvailable(isShowAllAvailable())
  }, [isMobile, isTablet, isTabletLandscape, isDesktop, articles])

  return (
    <div className="items-wrapper">
      <div className={`items mt-12 ${showAll ? "show-all" : ""}`}>
        {articles.map((article) => (
          <InsightsAcademyCard
            key={article.id}
            article={article}
            className="item"
            tier={tier}
          />
        ))}
      </div>
      {!showAll && showAllAvailable && (
        <div onClick={() => setShowAll(true)} className="load-more mt-11">
          Load More
        </div>
      )}
    </div>
  )
}

const DemandAcademyTiers = ({ features, className }) => {
  const data = useStaticQuery(graphql`
    query GetDemandAcademyTiers {
      gcms {
        demandAcademyTiers(orderBy: order_ASC) {
          id
          name
          subtitle
          summary
          color {
            css
            hex
          }
          tertiaryColor {
            css
            hex
          }
          labelGradient
          icon {
            url
          }
          insightsArticles {
            id
            slug
            title
            demandAcademySlug
            demandAcademyTitle
            demandAcademyTheme
            demandAcademySummary {
              text
              html
            }
            exclusiveToDemandAcademy
            featuredImage {
              url(
                transformation: {
                  image: { resize: { width: 780, fit: clip } }
                  document: { output: { format: jpg } }
                }
              )
              altText
              handle
            }
          }
        }
      }
    }
  `)

  const { demandAcademyTiers: tiers } = data.gcms

  return (
    <Styles className={className}>
      {tiers.map((tier) => (
        <div className="tier" key={tier.id}>
          {tier.icon?.url && <img src={tier.icon.url} alt="" />}
          <h5 className="mt-3" style={{ color: tier.color.hex }}>
            {tier.subtitle}
          </h5>
          <div
            className="description mt-6"
            dangerouslySetInnerHTML={{
              __html: `<p>${tier.summary}</p>`,
            }}
          />
          <TierItems tier={tier} />
        </div>
      ))}
    </Styles>
  )
}

export default DemandAcademyTiers
