import React, { useRef } from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import { motion, useTransform, useScroll } from "framer-motion"
import tw, { styled } from "twin.macro"
import HubspotForm from "react-hubspot-form"

import Layout from "components/layout"
import SEO from "components/seo"
import YouTube from "components/youtube"
import DemandAcademyFeatures from "components/sections/demand-academy-features"
import DemandAcademyHeader from "components/sections/demand-academy-header"
import DemandAcademyTiers from "components/sections/demand-academy-tiers"
import { getSharePreviewUrls } from "utils/share-preview"
import BgBody from "images/bg/demand-academy-body.jpg"

const Styles = styled.div`
  .section {
    ${tw`mx-auto px-2 m:px-4 l:px-0 l:max-w-l`}
  }

  .body {
    ${tw`pb-64`}

    .logo {
      margin-top: 42px;
      width: 70%;

      @media (min-width: 640px) {
        margin-top: 66px;
        width: 50%;
      }

      @media (min-width: 1024px) {
        margin-top: 100px;
        width: 35%;
      }
    }

    .sub-section {
      margin-top: 132px;

      @media (min-width: 640px) {
        margin-top: 166px;
      }

      @media (min-width: 1024px) {
        margin-top: 200px;
      }
    }

    .description {
      ${tw`text-grey-65 text-base l:text-xl text-center`}

      h3 {
        ${tw`text-white font-extrabold text-4xl m:text-5xl l:text-6xl leading-tight m:leading-tight l:leading-tight mb-6`}
      }
    }

    .video {
      ${tw`w-full m:w-4/5 -mt-12 l:-mt-20`}

      @media (min-width: 1024px) {
        width: 70%;
      }

      .video-preview {
        ${tw`border-2 border-white border-opacity-30`}

        border-radius: 10px;
      }

      .gradient {
        ${tw`block`}

        border-radius: 8px;
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);

        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .subscribe-form {
      ${tw`mt-12 m:mt-20 l:mt-24 mx-auto`}

      width: 100%;

      @media (min-width: 640px) {
        width: 450px;
      }
    }
  }
`

const HeaderBackgroundSection = styled.div`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
`

const BodyBackgroundSection = styled.div`
  background: ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-color: #0d0023;
`

const DemandAcademy = ({ data }) => {
  const { gcms } = data

  const { page } = gcms

  const tiersTitle = "<h3>Let's get started!</h3>"

  const contentRef = useRef()

  // staggered fade in animations
  const headerList = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.15,
        duration: 0.5,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }

  // parallax
  const { scrollY } = useScroll()
  const y4 = useTransform(scrollY, [0, 700, 1000], [20, -150, -150])

  function goToContent() {
    const { top } = contentRef.current.getBoundingClientRect()
    window.scrollTo({ top: top - 80, behavior: "smooth" })
  }

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <Styles>
        <HeaderBackgroundSection bg={page.headerImg?.url}>
          <DemandAcademyHeader
            className="academy"
            page={page}
            onClick={goToContent}
          />
        </HeaderBackgroundSection>
        <BodyBackgroundSection bg={BgBody} className="body">
          <div className="section flex flex-col items-center">
            {page.featuredVideo?.youtubeId && (
              <motion.div
                className="video"
                initial="hidden"
                animate="visible"
                variants={headerList}
                style={{ y: y4 }}
              >
                <YouTube
                  videoId={page.featuredVideo.youtubeId}
                  preview={page.featuredImage?.url}
                  caption={page.featuredVideo.label}
                  autoplay={true}
                />
              </motion.div>
            )}
            <div
              className="description mt-12"
              dangerouslySetInnerHTML={{
                __html: `<div>${page.body?.html}</div>`,
              }}
            />
            {page.extraData?.features && (
              <DemandAcademyFeatures
                className="academy"
                features={page.extraData.features}
              />
            )}
            <div
              className="description mt-48 mb-14"
              ref={contentRef}
              dangerouslySetInnerHTML={{
                __html: `<div>${tiersTitle}</div>`,
              }}
              id="resources"
            />
            <DemandAcademyTiers className="academy" />
            <div className="description sub-section" id="subscribe">
              <h3>And this is just the beginning.</h3>
              <div>
                We're developing a Demand Certification to help you apply your
                learnings and start taking action. Sign up now to be alerted
                when the program launches.
              </div>
              <div className="subscribe-form">
                <HubspotForm
                  portalId={process.env.GATSBY_HUBSPOT_ID}
                  formId={process.env.GATSBY_HUBSPOT_DEMAND_ACADEMY_FORM}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </BodyBackgroundSection>
      </Styles>
    </Layout>
  )
}

export default DemandAcademy

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/academy" }) {
        ...PageData
        headerImg: headerImage {
          url(
            transformation: {
              image: { resize: { width: 1920, fit: clip } }
              document: { output: { format: webp } }
              validateOptions: true
            }
          )
        }
      }
    }
  }
`
