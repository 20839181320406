import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import { brandRules } from "utils/brand"
import { Frame, FeaturedImage, Title } from "components/insights-card"
import Tag from "components/tag"
import { daArticlePath, daArticleTitle } from "utils/demand-academy"

const CardFrame = styled(Frame)`
  min-height: auto;

  &:hover {
    .cover:before {
      ${tw`invisible opacity-0`}
    }
  }
`

const CardImage = styled(FeaturedImage)`
  ${tw`w-full mb-0`}

  aspect-ratio: 1.775;
  clip-path: none;
`

const CardCoverImage = styled.div`
  ${tw`w-full h-full relative`}

  ${({ bg }) => `
    background: url(${bg});
    background-size: cover;
    background-position: center;
  `}

  &:before {
    ${tw`w-full h-3/5 absolute bottom-0 left-0 visible opacity-100 transition-opacity duration-300`}

    content: "";
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`

const InsightsAcademyCard = ({ article, tier, className = "" }) => {
  const { featuredImage } = article

  const to = daArticlePath(article)
  const coverImage = featuredImage?.url
  const boxShadow = tier.tertiaryColor?.hex
    ? `0px 2px 10px ${tier.tertiaryColor.hex}99`
    : ""

  return (
    <CardFrame className={`w-full flex flex-col flex-grow-0 ${className}`}>
      <Link to={to} className="flex flex-col">
        {coverImage && (
          <CardImage className="bg-grey-15 flex flex-col overflow-hidden relative">
            <CardCoverImage bg={coverImage} className="flex cover" />
            {tier.name && (
              <Tag
                className="z-10 absolute left-0 bottom-0"
                background={tier.labelGradient}
                boxShadow={boxShadow}
              >
                {tier.name}
              </Tag>
            )}
          </CardImage>
        )}
        <div className="pt-2 font-body flex flex-col items-start">
          <Title
            className={`font-display text-lg leading-tight text-white`}
            dangerouslySetInnerHTML={{
              __html: brandRules(daArticleTitle(article)),
            }}
          />
        </div>
      </Link>
    </CardFrame>
  )
}

export default InsightsAcademyCard
